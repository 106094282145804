.custom-table-op {
  border-collapse: collapse;
}

.custom-table-op,
.custom-table-op th,
.custom-table-op td {
  border: 1.5px solid black !important;
  font-size: 35px;
}

.custom-table-op .table-success > td {
  background-color: #aee357 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  font-size: 35px;
}

.custom-table-op .table-primary > th {
  font-weight: 700;
  background-color: #4d97f6 !important;
  font-size: 35px;
}

.custom-table {
  border-spacing: 0; /* Prevent extra spacing between cells */
  border-collapse: separate; /* Allows spacing and box-shadow */
}

.custom-table th,
.custom-table td {
  font-weight: 700;
  font-size: large;
  background-color: #fff; /* Cell background */
  border: 1px solid #dee2e6; /* Cell border */
  padding: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.custom-table th {
  font-weight: 700;
  font-size: medium;
  background-color: #aee357; /* Header background */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow for header */
}

.custom-table tbody tr:nth-child(even) td {
  background-color: rgba(0, 123, 255, 0.1); /* Slight striped effect */
}

.custom-table tbody tr:nth-child(odd) td {
  background-color: #d4edda; /* Alternate row background */
}
