.background-overlay .box {
    display: inline-block;
    position: absolute;
    color: #f9d529;
    top: 50%;
    left: 50%;
    line-height: normal;
    font-size: 70px;
    transform: translate(-50%, -50%);
}


.background-overlay {
    min-height: 100vh;
    position: fixed;
    z-index: 1100;
    top: 0;
    background: #000000b3;
    width: 100%;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display:'';
}



.loader-18 {
    position: relative;
}

    .loader-18:before, .loader-18:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        border: .1em solid transparent;
        border-bottom-color: currentcolor;
        top: 0;
        left: 0;
        -webkit-animation: 1s loader-18 linear infinite;
        animation: 1s loader-18 linear infinite;
    }

    .loader-18:before {
        width: 1em;
        height: 1em;
    }

    .loader-18:after {
        width: .8em;
        height: .8em;
        top: .1em;
        left: .1em;
        animation-direction: reverse;
    }

@-webkit-keyframes loader-18 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-18 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}