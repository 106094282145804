@import url('https://fonts.googleapis.com/css2?family=Mitr&family=Sigmar&family=Ubuntu:wght@500&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.0/css/select2.min.css);


body{
  background-color: #fafafa;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
#timeline{
  overflow: hidden;
}
.rotate {
  height:80px;
  white-space: nowrap;
  position:relative;
}


/* .css-2613qy-menu{
  width: 150px !important;
} */

.circle-live{
  margin-top: 85px;
    width:120px;
    height:120px;
    border-radius:100px;
    font-size:15px;
    color:black;
    line-height:120px;
    text-align:center;
    
    }


.rotate > div {
  transform: rotate(90deg);
  position:absolute;
  left:0;
  right:0;
  top: 10px;
  margin:auto;
  padding-top: 10px;
  
}

* Dropdown Button */
.dropbtn {
 
  color: white;
  /* padding: 16px; */
  font-size: 13px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* remove arrow input type number */
input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media (min-width: 2879px)  {
  .chartCard {width: 10% !important;}
}
@media  (min-width: 2126px) and  (max-width: 2879px) {
  .chartCard {width: 10% !important;}
}
@media  (min-width: 1748px) and  (max-width: 2125px) {
  .chartCard {width: 12.50% !important;}
}
@media  (min-width: 1370px) and  (max-width: 1747px) {
  .chartCard {width: 14.27% !important;}
}

@media  (min-width: 992px) and  (max-width: 1369px) {
  .chartCard {width: 16.3333337% !important;}
}
@media (min-width: 576px) and (max-width: 991px){
  .chartCard {width: 25% !important;}
}




.progress-bar-vertical{
  width: 30px;
  min-height: 180px;
  margin-right: 20px;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column-reverse;
}   


.progress-bar-vertical .progress-bar{
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  display:block;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.common-anchor{
  text-align: left;
  position: relative;
  border-collapse: collapse; 
}
.common-anchor{
 z-index: 1;
  position: sticky;
  
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0);
}



.common {
  text-align: left;
  position: relative;
  border-collapse: collapse; 
}
.common th, td {
  padding: 0.25rem;
}
.common tr.red th {
  background: red;
  color: white;
}
.common tr.green th {
  background: green;
  color: white;
}
.common tr.purple th {
  background: purple;
  color: white;
}
.common th {
  /* background: lightblue; */
  background:#c6c0fe;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}


.borderless td, .borderless th {
  border: none;
}

.filter-button-downtime-entry {
  padding: 15px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.dropbtn-btn-filter-group::after {
  display: none !important; 
}

.dropbtn-btn-filter-group{
  background-color: white;
    color: darkslategray;
    border: solid 0.5px lightgray;
    margin-right: 12px;
}

.main-div{
  background-color: #fff;
  border: 1px solid #b0bed0;
  border-radius: 1rem;
  /* height: 99%; */
  padding-left: 10px;
  padding-top: 5px;
  overflow: hidden;
  min-height: 78vh;
}


.detail-modal .modal-content{
  width: 115% !important;
  margin-left: -40px !important;
  margin-top: -23px !important;
}


.sticky {
  position: absolute;
  position: sticky;
  left: 0;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  font-size: 16px;
  z-index: 999;
}





/* Example CSS */
/* Example CSS */

.google-visualization-tooltip-item {
  text-align: center; /* Center the annotation text */
}

.bg-for-dishead{

  background-color: rgb(255, 255, 255);
background-color: rgba(212, 190, 215, 0.4) ; 
}
.logo:hover {
  transform: rotateY(360deg);
  transition: transform 1.3s ease-in-out
  ;
}




#checkbox {
  display: none;
}

.toggle {
  position: relative;
  padding: 5px;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: .3s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(253, 255, 243);
  border-radius: 5px;
  transition-duration: .3s;
}

/* #checkbox:checked + .toggle .bars {
  margin-left: 13px;
} */

#checkbox:checked + .toggle #bar2 {
  transform: translateY(14px) rotate(60deg);
  margin-left: 0;
  transform-origin: right;
  transition-duration: .3s;
  z-index: 2;
}

#checkbox:checked + .toggle #bar1 {
  transform: translateY(28px) rotate(-60deg);
  transition-duration: .3s;
  transform-origin: left;
  z-index: 1;
}

#checkbox:checked + .toggle {
  transform: rotate(+90deg);
}
/* #checkbox:checked + .toggle #bar3 {
  transform: rotate(90deg);
  transition-duration: .3s;
  transform-origin:right;
} */





.pointer-events-none {
  pointer-events: none;
}
.pointer-events-pointer{
  pointer-events:all
}








.cl-toggle-switch {
  position: relative;
 }
 
 .cl-switch {
  position: relative;
  display: inline-block;
  
 }
 .cl-toggle-span{
  padding: 15px;
  border-radius: 10px;
  background-color:#e0edff ;
  border: 1px solid #aed1ff;
 }
 .cl-switch span{
  color: #2d88ff;
  font-family: 'Ubuntu', sans-serif;
 }
 /* Input */
 .cl-switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: 6px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(0, 0, 0, 0.38);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
 }
 /* Track */
 .cl-switch > span::before {
  content: "";
  float: right;
  display: inline-block;
  margin: 5px 0 5px 10px;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  background-color: rgb(0, 0, 0, 0.38);
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
 }
 /* Thumb */
 .cl-switch > span::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
 }
 /* Checked */
 .cl-switch > input:checked {
  right: -10px;
  background-color: #85b8b7;
 }
 
 .cl-switch > input:checked + span::before {
  background-color: #85b8b7;
 }
 
 .cl-switch > input:checked + span::after {
  background-color: #018786;
  transform: translateX(16px);
 }
 /* Hover, Focus */
 .cl-switch:hover > input {
  opacity: 0.04;
 }
 
 .cl-switch > input:focus {
  opacity: 0.12;
 }
 
 .cl-switch:hover > input:focus {
  opacity: 0.16;
 }
 /* Active */
 .cl-switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
 }
 
 .cl-switch > input:active + span::before {
  background-color: #8f8f8f;
 }
 
 .cl-switch > input:checked:active + span::before {
  background-color: #85b8b7;
 }
 /* Disabled */
 .cl-switch > input:disabled {
  opacity: 0;
 }
 
 .cl-switch > input:disabled + span::before {
  background-color: #ddd;
 }
 
 .cl-switch > input:checked:disabled + span::before {
  background-color: #bfdbda;
 }
 
 .cl-switch > input:checked:disabled + span::after {
  background-color: #61b5b4;
 }








 .uiverse {
  position: relative;
  background: #ffffff;
  color: #000;
  padding: 0px;
  margin: 10px;
  border-radius: 10px;
 border :1px solid rgb(186, 186, 255);
  width: 145px;
  height: 48px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

svg:hover span,
svg:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.uiverse:hover,
.uiverse:hover .tooltip,
.uiverse:hover .tooltip::before {
  background: linear-gradient(320deg, rgb(53, 117, 176), rgb(148, 169, 240));
  color: #ffffff;
}
 


.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #B0C4DE;
  color: #ffffff;
  text-align: left;
  
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid#B0C4DE;
}

.Plantview{
  background-image: linear-gradient(to right top, #051937, #273758, #48587a, #6a7c9e, #8ea1c4);
}

.heading-bg{
  background-image: linear-gradient(to right top, #051937, #273758, #48587a, #6a7c9e, #8ea1c4);
position: sticky; top:0;
z-index: 1000;
}




#exTab1 .tab-content {
  color : white;
  background-color: #428bca;
  padding : 5px 15px;
}

#exTab2 h3 {
  color : white;
  background-color: #428bca;
  padding : 5px 15px;
}

/* remove border radius for the tab */

#exTab1 .nav-pills > li > a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0 ;
}

#exTab3 .tab-content {
  color : white;
  background-color: #428bca;
  padding : 5px 15px;
}




.animated-gradient {
  animation: animateBg 2s linear infinite;
  background-image: linear-gradient(90deg,#c2c2c2,#e6e6e6,#c2c2c2,#c2c2c2,#e6e6e6);
  background-size: 400% 100%;
}
@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}


.mobile-only {
  display: none;
}
.desktop-only {
  display: none;
}

/* Media query for mobile screens */
@media only screen and (max-width: 767px) {
  .mobile-only {
    display: block; /* or any other display value you want for mobile */
  }
.histogram-width{
  width: 100%;
}
  .Histrogram{
    display: flex;
    flex-direction: column;
  }
  .table-screen{
    overflow-x: auto;
  }
}
@media only screen and (min-width: 768px) {
  .desktop-only {
    display: block; /* or any other display value you want for desktop */
  }
  .Histrogram{
    display: flex;
    
  }
  .histogram-width{
    width: 50%;
  }
}

.gradient-background {
background-color:#6489f2;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.Qualitysummary{
  width: 75vw;
  display: flex;
  flex-wrap: wrap;
}

 div .ytp-show-cards-title {
  display: none;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 7px;
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888; 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.nav-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dropbtn-btn-filter-group {
  border: none;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item input {
  margin-right: 8px;
}

label.small {
}

#cssportal-grid {
	display: grid;
	grid-template-rows: repeat(6, 1fr);
	grid-template-columns: repeat(6, 1fr);
	gap: 5px;
	width: 100%;
	height: 100%;
  background: rgb(139, 138, 138);
  background: linear-gradient(18deg, rgb(129, 127, 127) 0%, rgb(80, 79, 79) 76%, rgba(3,2,2,1) 100%);
  /* background: linear-gradient(135deg, #0d1117, #161b22, #1b1f27); */
}
#div1 {
	grid-area: 1 / 1 / 3 / 3;
	/* background-color: rgba(45,41,111, 0.5); */
}
#div2 {
	grid-area: 3 / 1 / 5 / 3;
	/* background-color: rgba(223,102,56, 0.5); */
}
#div3 {
	grid-area: 5 / 1 / 7 / 3;
	/* background-color: rgba(89,229,179, 0.5); */
}
#div4 {
	grid-area: 4 / 3 / 7 / 7;
	/* background-color: rgba(95,176,175, 0.5); */
}
#div5 {
	grid-area: 1 / 3 / 4 / 7;
	/* background-color: rgba(18,248,173, 0.5); */
}
#div6 {
	grid-area: 1 / 3 / 4 / 5;
	/* background-color: rgba(192,45,178, 0.5); */
}
#div7 {
	grid-area: 1 / 5 / 4 / 7;
	/* background-color: rgba(57,224,55, 0.5); */
}

.box-glow {
  box-shadow: 0 0 4px 4px rgba(250, 250, 250, 0.7); /* Glowing green shadow */
}